import React, {useEffect, useState} from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import {ICompany} from "../models/company";
import Button from "../components/button";
import JobListItemMobile from "../components/jobs/jobs-list-item-mobile";
import JobListItemDesktop from "../components/jobs/jobs-list-item-desktop";
import isPrevDate from "../utils/isPrevDate";
import {IJobs} from "../models/jobs";
const github = require("../images/github.svg");
const techcrunch = require("../images/techcrunch.svg");
const linkedin = require("../images/linkedin.svg");
const crunchbase = require("../images/crunchbase.svg");
const facebook = require("../images/facebook.svg");
const instagram = require("../images/instagram.svg");

const SocialIcon: React.FC<{link: string; src: string; alt: string}> = ({
  link,
  src,
  alt,
}) => (
  <>
    {link && (
      <a href={link} target="_blank">
        <img src={src} alt={alt} title={alt} className="mr-7 h-8 w-8" />
      </a>
    )}
  </>
);
const Startup = ({data}) => {
  const company = data.strapiStartups as ICompany;
  const departments = data.allStrapiDepartments.nodes as Array<{
    strapiId: string;
    name: string;
  }>;
  const locations = data.allStrapiLocations.nodes as Array<{
    strapiId: string;
    location: string;
  }>;

  const findDept = (id) => {
    const dept = departments.find((dept) => dept.strapiId === id);
    return dept ? dept.name : undefined;
  };
  const findLocation = (id) => {
    const location = locations.find((loc) => loc.strapiId === id);
    return location ? location.location : undefined;
  };
  const [jobs, setJobs] = useState<IJobs[]>([]);
  useEffect(() => {
    setJobs(
      company.jobs
        .filter((job) => !job.expirationDate || !isPrevDate(job.expirationDate))
        .map((job) => ({
          ...job,
          department: {name: findDept(job.department)},
          location: {location: findLocation(job.location)},
        }))
    );
  }, [company.jobs]);
  // const relatedArticles = data.allStrapiBlogs.nodes as blog[];

  const WebLink = ({link, children, className}) => (
    <>
      {link && (
        <a
          className={className}
          href={company.websiteFull}
          target="_blank"
          rel="noppener noreferrer"
        >
          {children}
        </a>
      )}
    </>
  );
  return (
    <Layout>
      <SEO title={company.name} />
      <div className="pt-6 md:pt-24 mb-14 md:mb-20">
        <div className="flex mb-4">
          {company.logo && (
            <img
              className="mr-6 w-20 h-20 rounded object-contain"
              src={company.logo.publicURL}
              alt={company.name}
            />
            // <Img
            //   fluid={company.logo.childImageSharp.fluid}
            //   alt="logo"
            //   className="mr-6 w-20 h-20 rounded "
            // />
          )}
          <div className="flex flex-col py-3">
            <div className="flex">
              <h1 className="text-2xl font-bold md:mb-4 md:mr-4">
                {company.name}
              </h1>
              <WebLink
                className="hidden md:block md:h-8 md:pt-1"
                link={company.websiteFull}
              >
                <Button small>Visit Website</Button>
              </WebLink>
            </div>
            <span className="text-sm">{company.headquarter}</span>
          </div>
        </div>
        <div className="flex mb-6 md:ml-26  md:w-2/6">
          <SocialIcon
            link={company.crunchbase}
            src={crunchbase}
            alt="crunchbase"
          />
          <SocialIcon link={company.facebook} src={facebook} alt="facebook" />
          <SocialIcon
            link={company.instagram}
            src={instagram}
            alt="instagram"
          />
          <SocialIcon link={company.linkedin} src={linkedin} alt="linkedin" />
          <SocialIcon link={company.github} src={github} alt="github" />
          <SocialIcon
            link={company.techcrunch}
            src={techcrunch}
            alt="techcrunch"
          />
        </div>

        <div className="flex justify-between md:justify-start mb-6  md:ml-26 md:w-2/6">
          {company.sector && (
            <div className="flex md:flex-col md:mr-14">
              <span className="font-bold mr-2"> Sector</span>
              <span>{company.sector.name}</span>
            </div>
          )}
          {company.fundingStage && (
            <div className="flex md:flex-col">
              <span className="font-bold mr-2">Stage</span>
              <span>{company.fundingStage}</span>
            </div>
          )}
        </div>

        <WebLink link={company.websiteFull} className="md:hidden">
          <Button className="w-full mb-8">Visit Website</Button>
        </WebLink>

        <ReactMarkdown
          className=" md:ml-26 markdown"
          source={company.longDescription}
          escapeHtml={false}
        />
      </div>
      {jobs.length ? (
        <div className="mb-20">
          <h2 className="text-2xl font-bold mb-12">Join the team </h2>

          {jobs.map((job) => (
            <>
              <JobListItemMobile job={job} displayCompany={false} />
              <JobListItemDesktop job={job} displayCompany={false} />
            </>
          ))}
        </div>
      ) : null}
    </Layout>
  );
};

export default Startup;
export const query = graphql`
  query getStartup($id: String!) {
    strapiStartups(id: {eq: $id}) {
      sector {
        name
      }
      crunchbase
      facebook
      fundingStage
      github
      headquarter
      id
      instagram
      linkedin
      longDescription
      name
      slug
      shortDescription
      techcrunch
      websiteFull
      jobs {
        id
        department
        expirationDate
        title
        remote
        published
        location
        industry
        learnMoreLink
      }
      logo {
        # childImageSharp {
        #   fluid {
        #     ...GatsbyImageSharpFluid
        #   }
        # }
        publicURL
      }
    }
    allStrapiDepartments {
      nodes {
        strapiId
        name
      }
    }
    allStrapiLocations {
      nodes {
        strapiId
        location
      }
    }
  }
`;
